import axios from "axios";
import React, { useEffect, useState } from "react";
import ExamType from "./ExamType";

const API_URL_EXAM = "https://lit-education.herokuapp.com/api/exam/";
const API_URL_SCORES = "https://lit-education.herokuapp.com/api/score/";

export default function TotalExams() {
  const [exams, setExams] = useState([]);

  let getExams = axios.get(API_URL_EXAM + "fetch-exams");
  let getScores = axios.get(
    API_URL_SCORES +
      "fetch-scores-user/" +
      JSON.parse(localStorage.getItem("user")).email
  );

  const getExamsandScores = async () => {
    let scoresData = [];
    let examData = [];
    await axios
      .all([getExams, getScores])
      .then(
        axios.spread((...responses) => {
          examData = responses[0].data;
          scoresData = responses[1].data;
          let scoresDict = {};
          for (let i = 0; i < scoresData.length; i++) {
            scoresDict[scoresData[i].exam_name] = scoresData[i];
          }
          for (let i = 0; i < examData.length; i++) {
            if (examData[i].exam_name in scoresDict) {
              examData[i]["score"] =
                scoresDict[examData[i].exam_name].total_score;
            } else {
              examData[i]["score"] = -1;
            }
          }
          setExams(examData);
        })
      )
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getExamsandScores();
    // eslint-disable-next-line
  }, []);

  const examTypes = [
    "Business Administration Core",
    "Business Management + Administration",
    "Hospitality + Tourism",
    "Entrepreneurship",
    "Marketing",
    "Finance",
  ];

  return (
    <div className="total-exams">
      {examTypes.map((examType, index) => (
        <ExamType
          key={index}
          examTypeName={examType}
          examList={exams.filter((exam) => exam.exam_type === examType)}
        />
      ))}
    </div>
  );
}
