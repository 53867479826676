import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const API_URL_SCORE = "https://lit-education.herokuapp.com/api/score/";

export default function MCRadarGraph(props) {
  const [scoreData, setScoreData] = useState([]);

  async function getScores() {
    axios
      .get(
        API_URL_SCORE +
          "fetch-scores-category-user/" +
          JSON.parse(localStorage.getItem("user")).email
      )
      .then((response) => {
        const scoreData = response.data;
        setScoreData(scoreData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getScores();
    // eslint-disable-next-line
  }, []);
  const data = {
    labels: ["MKTG", "HOSP", "ENT", "FIN", "BMA", "BA"],
    datasets: [
      {
        data: scoreData,
        backgroundColor: "rgba(29, 123, 148, 0.2)",
        borderColor: "rgba(29, 123, 148, 1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="mc-radar-graph-grid ">
      <div className={"mc-graph-div " + props.className}>
        <div className="graph-sizing">
          <Radar
            data={data}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Category Breakdown",
                  color: "#000000",
                  font: {
                    size: 20,
                    family: "Rubik",
                  },
                },
                legend: {
                  display: false,
                },
              },
              scales: {
                r: {
                  ticks: {
                    color: "#000",
                    font: {
                      family: "Rubik",
                    },
                  },
                  pointLabels: {
                    color: "#000",
                    font: {
                      size: 13,
                      family: "Rubik",
                    },
                  },
                  min: 0,
                  max: 100,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
}
