import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const API_URL_SCORE = "https://lit-education.herokuapp.com/api/score/";

export default function ExamHeader(props) {
  let completed = props.score === -1 ? false : true;
  let navigate = useNavigate();
  let paid = true;
  if (
    ["BA-1", "BMA-1", "HOSP-1", "ENT-1", "MKTG-1", "FIN-1"].includes(
      props.examName
    )
  ) {
    paid = false;
  }

  let userPaid = sessionStorage.getItem("paid");
  let locked = true;
  if (userPaid === "true" || paid === false) {
    locked = false;
  }

  let buttonText = "";
  if (locked) {
    buttonText = "locked";
  } else if (completed) {
    buttonText = "review";
  } else {
    buttonText = "start";
  }

  const handleStart = () => {
    navigate("/ExamMC", { state: { examName: props.examName } });
  };

  async function handleReset() {
    try {
      await axios
        .post(API_URL_SCORE + "completed-exam/delete", {
          email: JSON.parse(localStorage.getItem("user")).email,
          examName: props.examName,
        })
        // TODO: Notification
        .then();
    } catch (err) {
      console.log(err);
    }
    window.location.reload(false);
  }

  return (
    <div
      className="exam-header"
      style={
        completed
          ? { borderLeft: "4px solid #1976d2" }
          : { borderLeft: "4px solid #d32f2f" }
      }
    >
      <div className="exam-name">Exam: {props.examName}</div>
      <div className="exam-info">
        <div className="score">Score: {completed ? props.score : "-"}/100</div>
      </div>
      <div className="exam-header-buttons">
        <Button
          style={{ width: "5.5rem", fontFamily: "rubik" }}
          variant="contained"
          color="error"
          disabled={completed ? false : true}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          style={{ width: "5.5rem", fontFamily: "rubik" }}
          onClick={handleStart}
          variant="contained"
          disabled={locked}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
