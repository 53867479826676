import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/LockPerson";
import Copyright from "../Copyright";
import * as yup from "yup";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firebaseErrorMessage } from "./firebase";
import axios from "axios";

const theme = createTheme();
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const API_URL_AUTH = "https://lit-education.herokuapp.com/api/auth/";

export default function Signup(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const signinSchema = yup.object().shape({
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Password Confirmation Required"),
    password: yup
      .string()
      .min(5, "Password must be at least 5 characters")
      .matches(passwordRules, {
        message:
          "Password must have one uppercase character, lowercase character, and number",
      })
      .required("Password Required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email Required"),
    name: yup.string().required("Full Name Required"),
  });

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangePasswordConfirmation = (e) => {
    const PasswordConfirmation = e.target.value;
    setPasswordConfirmation(PasswordConfirmation);
  };

  const signup = async (name, email) => {
    await axios
      .post(API_URL_AUTH + "signup", {
        name: name,
        email: email,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setLoading(true);
    signinSchema
      .validate({
        name: name,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      })
      .then(() =>
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            setErrorMessage("");
            signup(name, email);
            const userStorage = { email: userCredential.user.email };
            localStorage.setItem("user", JSON.stringify(userStorage));
            props.history.push("/Dashboard");
            window.location.reload();
          })
          .catch((error) => {
            const errorCode = error.code;
            setLoading(false);
            setErrorMessage(firebaseErrorMessage(errorCode));
          })
      )
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.message);
      });
  };

  const user = auth.currentUser;
  if (user !== null) {
    return <Navigate to="/Dashboard" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="auth signup">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#00a9ac" }}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box
            component="form"
            onSubmit={handleSignup}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={onChangeName}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={onChangeEmail}
            />
            <Box display="flex">
              <TextField
                margin="normal"
                sx={{ marginRight: "5px" }}
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={onChangePassword}
              />
              <TextField
                margin="normal"
                required
                sx={{ marginLeft: "5px" }}
                name="passwordConfirmation"
                label="Password Confirmation"
                type="password"
                id="passwordConfirmation"
                value={passwordConfirmation}
                onChange={onChangePasswordConfirmation}
              />
            </Box>
            <div className="auth-error-message">
              {errorMessage ? errorMessage : ""}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 0,
                mb: 2,
                backgroundColor: "#00a9ac",
                fontFamily: "rubik",
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Sign Up</span>
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                <Link href="/Login" variant="body2">
                  {"Already have an account? Log In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 2, mb: 3 }} />
      </Container>
    </ThemeProvider>
  );
}
