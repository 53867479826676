import React, { useState, useEffect } from "react";
import MCActivityCalendar from "./MCActivityCalendar";
import MCRadarGraph from "./MCRadarGraph";
import MCLineGraph from "./MCLineGraph";
import axios from "axios";

const API_URL_SCORE = "https://lit-education.herokuapp.com/api/score/";

export default function Analytics() {
  const [scoreData, setScoreData] = useState([]);

  async function getScores() {
    axios
      .get(
        API_URL_SCORE +
          "fetch-scores-user/" +
          JSON.parse(localStorage.getItem("user")).email
      )
      .then((response) => {
        const scoreData = response.data;
        setScoreData(scoreData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getScores();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <MCLineGraph scoreData={scoreData} />
      <MCRadarGraph scoreData={scoreData} />
      <MCActivityCalendar scoreData={scoreData} />
    </React.Fragment>
  );
}
